.swiper-pagination{
    position: relative !important;
    margin-top:20px !important;
}


.swiper-button-next, .swiper-button-prev{
    color: #000000 !important;
    margin-top: calc(-40px - (44px / 2)) !important;
}

