@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    font-family: 'Inter', sans-serif;
}
body{
    background-color: #0e2330;

}
html{
    scroll-behavior: smooth;
}


